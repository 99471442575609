import * as React from 'react';
// import { Container, Stack, Layer } from './styles';

interface GlitchProps {
  children: React.ReactNode;
}

// const totalStacks = 6;

export function Glitch(props: GlitchProps) {

  return <>{props.children || <></>}</>

  // const stack = React.useMemo(() => {
  //   return Array.from(Array(10).keys());
  // }, []); 

  // return (
  //   <Container>
  //     <Stack>
  //       {stack.map(index => (
  //         <Layer stacks={totalStacks} index={index}>
  //           {props.children || <></>}
  //         </Layer>
  //       ))}
  //     </Stack>
  //   </Container>
  // )
}
  
