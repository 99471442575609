import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 0px;
  position: relative;
`;

export const Screen = styled.div`
  background-color: black;
  position: absolute;
  top: 7%;
  bottom: 13%;
  left: 15%;
  right: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Laptop = styled.div`
  position: relative;
`;

export const Phone = styled.div`
  position: absolute;
  right: 5%;
  bottom: 15%;
  width: 15%;
`;

export const PhoneScreen = styled.div`
  background-color: black;
  position: absolute;
  top: 2%;
  bottom: 3%;
  left: 4%;
  right: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 1;
  background-color: black;
  overflow: hidden;
  @media (max-width: 768px) {
    border-radius: 20%;
  }
`;
