import * as React from 'react';
import { Header } from '../../components/Header';
import { NavBar } from '../../components/NavBar';
import { Paragraph } from '../../components/Paragraph';
import { Section } from '../../components/Section';
import { Spacer } from '../../components/Spacer';
import { Title } from '../../components/Title';
import { Image } from '../../components/Image';
import { Row } from '../../components/Row';
import { Wiggle } from '../../components/Wiggle';
import { Skill } from '../../components/Skill';
import { MaxWidth } from '../../components/MaxWidth';
import { Work } from '../../components/Work';
import { Column } from '../../components/Column';
// import { Copyright } from '../../components/Copyright';
import { Button } from '../../components/Button';

interface SkillSet {
  title: string;
  skills: string[];
}

export function HomePage() {

  const skills: SkillSet[] = [
    {
      title: 'General',
      skills: ['Javascript ES6', 'Typescript', 'Package Management', 'Docker', 'Version Control / Git', 'Automated Testing', 'Bundling'],
    },
    {
      title: 'Front End',
      skills: ['React', 'React Native', 'AngularJs', 'Angular', 'HTML 5', 'JSX', 'CSS 3', 'JSS', 'Cordova'],
    },
    {
      title: 'Back End',
      skills: ['NodeJs', 'PHP', 'SQL / MySQL', 'NoSQL / MongoDB', 'Terraform', 'Migrations', 'Data Ingestion', 'Query Builders', 'Event Based'],
    },
    {
      title: 'Notable Libraries',
      skills: ['Styled Components', 'Express', 'Material UI', 'Immutable', 'MomentJs', 'Twillio', 'Redux', 'React Query', 'Ffmpeg'],
    },
    {
      title: 'Design',
      skills: ['Photoshop', 'After Effects', 'Illustrator'],
    },
  ];

  const initialYear = new Date('2009-02-02');
  const today = new Date();
  const yearsCoding = today.getFullYear() - initialYear.getFullYear();

  return (
    <>

      <div className="scroll_top" />

      <NavBar />
      
      <Header />

      <Section>

        <div style={{ marginTop: -200 }} />

        <Wiggle>
          <Image src={require('../../assets/astoboy.png')} width={150} />
        </Wiggle>

        <Title size="l" className="scroll_about_me">ABOUT ME</Title>

        <Spacer size="s" />

        <div style={{ fontSize: 15, padding: 20, borderRadius: 10, fontWeight: 'bold', backgroundColor: 'rgba(255,255,255,0.05)' }}>
          I am no longer interested in Freelance projects
        </div>

        <Spacer size="s" />

        <Paragraph>
          
          My name is Dan Herbert, and I'm a Full Stack Developer near Kitchener Ontario. My favourite things are gaming, movies and coding.
          <br/><br/>
          I've been fascinated with code all my life and began coding professionally in 2009 on web-based dental software. Over the last {yearsCoding} years, I have built a diverse background working on full-time, freelance and personal projects. 
          <br/><br/>
          I've learned how to work in a blameless team environment, train new developers, share knowledge, accept and learn from failure, continuously integrate, and so much more. I'm inspired to see how I can evolve with technology and culture over the next decade. 

        </Paragraph>

        <Spacer size="m" />

        <Row>
          {/* <a target="_blank" rel="noreferrer" href="https://github.com/iisdan">
            <Image src={require('../../assets/github.png')} height={35} />
          </a> */}

          {/* <Spacer size="s" /> */}

          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/dan-herbert-7889b654/">
            <Image src={require('../../assets/linkedin.png')} height={50} />
          </a>
        </Row>

      </Section>

      <Section>

        <Wiggle>
          <Image src={require('../../assets/rocket.png')} width={150} />
        </Wiggle>

        <Title size="l" className="scroll_skillset">SKILLSET</Title>

        <Spacer size="s" />

        <Title size="s">Spotlight</Title>

        <MaxWidth>
          <Spacer size="s" />

            <Row>
            <Skill><Image src={require('../../assets/nodewhite.png')} height={50} /></Skill>
            
            <Skill><Image src={require('../../assets/reactjs.png')} height={50} /></Skill>
              {/* 
              <Image src={require('../../assets/angular.png')} height={50} /> */}
              
              <Skill><Image src={require('../../assets/php.png')} height={50} /></Skill>
              
              <Skill><Image src={require('../../assets/mongo.png')} height={50} /></Skill>
              
              <Skill><Image src={require('../../assets/mysql.png')} height={50} /></Skill>
              
              <Skill><Image src={require('../../assets/firebase.png')} height={50} /></Skill>
            </Row>

          <Spacer size="s" />
        </MaxWidth>

        {skills.map((skillset) => (
          <>
            <Title size="s">{skillset.title}</Title>
            <Spacer size="s" />

            <MaxWidth>
              <Row>
                {skillset.skills.map((skill) => (
                  <Skill>{skill}</Skill> 
                ))}
              </Row>
            </MaxWidth>

            <Spacer size="s" />
          </>
        ))}

      </Section>

      <Section>

        <Wiggle>
          <Image src={require('../../assets/laser.png')} width={150} />
        </Wiggle>

        <Title size="l" className="scroll_portfolio">PORTFOLIO HIGHLIGHTS</Title>

        <Spacer size="xs" />

        <Paragraph>
          Here are just a few of my most notable freelance and personal projects.
        </Paragraph>

        <Spacer size="s" />

        <Work 
          logo={<Image src={require('../../assets/pokerlogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/pokerweb.jpg')} width={'100%'} />}
          description={
            <>
              Planning poker is an easy and fast way for teams to score tasks in an agile enviroment in a remote work environment.
              <br/><br/>

              <Row horizontalAlign="center">
                <a target="_blank" rel="noreferrer" href="https://planningpokerintheaftertimes.com/" style={{ textDecoration: 'none', color: 'white' }}>
                  <Button>Visit</Button>
                </a>
              </Row>
            </>
          }
        />

        <Work 
          logo={<Image src={require('../../assets/ftlogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/desktopft.jpg')} width={'100%'} />}
          mobileImage={<Image src={require('../../assets/FTmobile.jpg')} width={'100%'} />}
          description={
            <>
              FlameTask is an advanced preventive maintenance application for the web accompanied by a mobile app for technicians. It will automatically determine scheduled and metered maintenance dates/times based on advanced options or another service date and generate internal, or client reports.
              {/* <br/><br/>

              <Row horizontalAlign="center">
                <a target="_blank" rel="noreferrer" href="http://flametask.com" style={{ textDecoration: 'none', color: 'white' }}>
                  <Button>Visit</Button>
                </a>
              </Row> */}
            </>
          }
        />

        <Work 
          logo={<Image src={require('../../assets/doccontrollogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/doccontrolweb.jpg')} width={'100%'} />}
          description={
            <>
              Datastore allows users to use advanced search options to quickly find documents within thousands of records. Users can sign out a document and request changes to be approved by administrators. Once approved it will keep a new version of metadata with the new file.
            </>
          }
        />

        <Work 
          logo={<Image src={require('../../assets/eatgreen.png')} width={300} />}
          webImage={<Image src={require('../../assets/eatgreenweb.jpg')} width={'100%'} />}
          description={
            <>
              Eat Green Organics is an organic grocery ordering web site that features new and recurring orders. Once ordered, the software will prepare sorting lists and a map for delivery drivers as well as administrative documents.
            </>
          }
        />

        <Work 
          logo={<Image src={require('../../assets/crowdcomlogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/crowdcomweb.jpg')} width={'100%'} />}
          description={
            <>
              CrowdCom is a website for suggesting new ideas for companies. Other customers can vote on ideas and companies can then reply and give out rewards. 
            </>
          }
        />

        {/* <Work 
          logo={<Image src={require('../../assets/freelancelogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/webfreelance.jpg')} width={'100%'} />}
          description={
            <>
              Freelance unity is an interactive contract between a freelancer and a client. Projects have stages that include a completion list and agreed upon payment.
            </>
          }
        /> */}

        {/* <Work 
          logo={<Image src={require('../../assets/highscorelogo.png')} width={300} />}
          webImage={<Image src={require('../../assets/highscoreweb.jpg')} width={'100%'} />}
          description={
            <>
              An application for tracking and displaying leaderboards on a TV in arcades and tournaments
            </>
          }
        /> */}

      </Section>

      <Section>

        <Column>
        
          <Image src={require('../../assets/logo.png')} width={200} opacity={0.5} />

          <Spacer size="xs" />

          {/* <Copyright /> */}

          {/* <Spacer size="s" /> */}

        </Column>

      </Section>

    </>
  )
}
  
