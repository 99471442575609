import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16px;
  color: white;
  background-color: rgba(255,255,255,0.05);
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  min-width: 150px;
  text-align: center;
  text-decoration: none;
  &:hover {
    background-color: rgba(255,255,255,0.1);
  }
`;
