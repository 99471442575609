import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16x;
  text-align: center;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.05);
  display: inline-block;
  padding: 22px 15px;
  margin: 5px;
  font-weight: bold;
  white-space: nowrap;
  min-width: 220px;
`;
