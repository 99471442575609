import * as React from 'react';
import { Glitch } from '../Glitch';
import { Image } from '../Image';
import { Particles } from '../Particles';
import { Container, Background } from './styles';

interface HeaderProps {
  
}

export function Header(props: HeaderProps) {
  return (
    <>
    <Container>

      <Glitch>
        <div style={{ paddingLeft: 40, paddingRight: 40, position: 'relative', boxSizing: 'border-box' }}>
          <Image src={require('../../assets/logo.png')} width={'400px'} />
        </div>
      </Glitch>

      <Background>
        

        {/* To get parallax we need to use 3 particlejs instances because of a bug in react-particles-js */}
        <Particles 
          size={3}
          opacity={0.5}
          density={8}
          speed={1}
        />
        <Particles 
          size={2}
          opacity={0.3}
          density={20}
          speed={0.6}
        />
        <Particles 
          size={1}
          opacity={0.3}
          density={60}
          speed={0.3}
        />
      </Background>
    </Container>
    </>
  )
}
  
